
.paper-admin {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    .selectedStep-top {
        margin: 0px 20px;
        border-bottom: 1px solid #e5e5e5;
        /*border-bottom: 1px solid #E5E5E5;*/
        box-sizing: border-box;
        display: -webkit-box;
        display: flex;
        justify-content: space-between;
        padding: 10px 120px;
        -webkit-box-align: center;
        align-items: center;
        .Pre {
            display: flex;
            display: -webkit-flex;
            -webkit-box-direction: normal;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
            line-height: 1;
            i {
                font-size: 24px;
                color: #2dc079;
            }
            span {
                display: inline-block;
                color: #2dc079;
            }
        }
        .step-content {
            display: flex;
            display: -webkit-flex;
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            .step-border {
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #d2d2d2;
                color: #ffffff;
                padding: 0 10px;
                border-radius: 2px;
                cursor: pointer;
                i {
                    padding-right: 6px;
                }
            }
            .blue {
                background: #2dc079;
            }
            .setp-line-bj {
                background: url("./../../../assets/images/admin/create-paper-active.png") center no-repeat !important;
                height: 12px;
                width: 110px;
                background-size: 100% 100%;
                margin: 0 28px;
            }
            .setp-line {
                background: url("./../../../assets/images/admin/create-paper-gray.png") center no-repeat;
                height: 12px;
                width: 110px;
                background-size: 100% 100%;
                margin: 0 28px;
            }
        }
        .Next {
            line-height: 1;
            display: flex;
            display: -webkit-flex;
            -webkit-box-direction: normal;
            align-items: center;
            flex-direction: column;
            font-size: 12px;
            cursor: pointer;
            i {
                font-size: 24px;
                margin-left: 6px;
                color: #2dc079;
            }
            span {
                display: inline-block;
                color: #2dc079;
            }
        }
    }
    .addPaper-content {
        margin-top: 20px;
        height: calc(100% - 130px);
        box-sizing: border-box;
        .addPaper-question-type {
            display: flex;
            display: -webkit-flex;
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
            -webkit-align-content: center;
            align-items: center;
            /*width: 100%;*/
            .question-type-title {
                font-size: 16px;
                font-weight: 400;
                color: #333;
            }
            .question-type-tab {
                display: flex;
                display: -webkit-flex;
                justify-content: flex-start;
                -webkit-justify-content: flex-start;
                -webkit-align-content: center;
                align-items: center;
                margin: 0;
                padding: 0 0 0 14px;
                li {
                    margin-right: 10px;
                    cursor: pointer;
                    width: 62px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    border: 1px solid #d2d2d2;
                    list-style: none;
                    font-size: 14px;
                    color: #666666;
                    border-radius: 2px;
                }
                .addPaperActive {
                    background: #2dc079;
                    border: 1px solid #2dc079;
                    color: #ffffff;
                }
            }
        }
        .filtration-type {
            display: flex;
            align-items: center;
            span {
                color: #333333;
                font-size: 16px;
                font-weight: 400;
                margin-right: 14px;
            }
        }
        .addPaper-item {
            background: #e7f6ef;
            height: 50px;
            line-height: 50px;
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            font-size: 12px;
            .addPaper-item-left {
                padding-left: 74px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                span {
                    border: 1px solid #d2d2d2;
                    padding: 0 10px;
                    height: 30px;
                    background: #fff;
                    line-height: 30px;
                    display: inline-block;
                    color: #333;
                    font-size: 14px;
                    font-weight: 400;
                    cursor: pointer;
                }
                .active {
                    background: #2dc079;
                    border-color: #2dc079;
                    color: #fff;
                }
                .addPaper-search {
                    margin-left: 22px;
                    ::v-deep .addPaper-search-input {
                        width: 350px;
                        .el-input__inner {
                            height: 32px;
                            line-height: 32px;
                        }
                    }
                }
            }
            .addPaper-item-right {
                padding-right: 88px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                .checkbox-answer {
                    margin-right: 62px;
                    ::v-deep .el-checkbox {
                        color: #666666;
                        font-size: 14px;
                    }
                }
                .total-title {
                    margin-right: 30px;
                    font-size: 14px;
                    color: #666666;
                    .text-num {
                        color: #e84932;
                        font-size: 16px;
                    }
                }
                .addPaper-BtnAll {
                    padding: 10px 8px;
                    font-size: 14px;
                    margin-right: 22px;
                    ::v-deep .el-icon-plus {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
                .basket {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i {
                        color: #e84932;
                        font-size: 32px;
                    }
                }
            }
        }
        .addPaper-typeBox {
            //overflow-y: auto;
            height: 100%;
            box-sizing: border-box;
            .nowrap-scrollbar-x {
                height: calc(100vh - 320px);
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        padding: 20px 64px;
                    }
                }
            }
        }
        .addPaper-paper-content {
            display: flex;
            height: calc(100% - 10px);
            box-sizing: border-box;
            padding: 0 64px 0 20px;
            .paper-content-left {
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                box-sizing: border-box;
                border: 1px solid #d2d2d2;
                margin-right: 16px;
                width: 350px;
                display: flex;
                flex-direction: column;
                .paper-message-header {
                    font-size: 18px;
                    border-bottom: 1px solid #eeeeee;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                }
                .paper-message {
                    display: flex;
                    flex-direction: column;
                    padding: 12px;
                    .message-item {
                        display: flex;
                        align-items: center;
                        margin: 0 0 12px 18px;
                        &:nth-child(3) {
                            margin-bottom: 16px;
                        }
                        &:last-of-type {
                            margin-left: 3px;
                            margin-bottom: 6px;
                        }
                        span {
                            margin-right: 8px;
                            font-size: 16px;
                        }
                    }
                }
                .exam-box {
                    margin-top: 8px;
                    padding: 0 18px 0 14px;
                    &:last-of-type {
                        margin-bottom: 10px;
                    }
                    .exam-box-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        line-height: 1;
                        .item-left {
                            i {
                                font-size: 14px;
                                font-weight: bold;
                                cursor: pointer;
                                display: inline-block;
                                margin-right: 8px;
                                color: #a0a0a0;
                            }
                            span {
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                    .exam-item-children {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 8px;
                        padding-left: 24px;
                        font-size: 14px;
                    }
                }
            }
            .paper-content-right {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                width: 1%;
                flex: 1;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .addPaper-typeBox2 {
                    height: 100%;
                }
            }
        }
        .addPaper-PreviewBox {
            display: flex;
            flex-direction: column;
            height: calc(100% - 10px);
            box-sizing: border-box;
            .addPaper-review-top {
                font-size: 18px;
                text-align: center;
                line-height: 1;
            }
            .Test-paper-box {
                flex: 1;
                height: 1%;
                box-sizing: border-box;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        padding: 64px 64px 0;
                    }
                }
            }
        }
        .create-success {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            line-height: 1;
            .success-img {
                width: 240px;
                height: 240px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            span {
                font-size: 24px;
                margin: 32px 0 36px;
            }
        }
    }
}
::v-deep .el-breadcrumb {
    margin: 0 20px;
}
.genera-breadcrumb-item {
    cursor: pointer;
}
.genera-breadcrumb-item:hover ::v-deep .el-breadcrumb__inner {
    transition: all 0.3s;
    color: #2dc079;
}
